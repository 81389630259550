import React, { Suspense, useEffect, useState } from "react";
import { Container, CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, Navigate } from "react-router-dom";
import api from "../api";
import { UiPropertiesContext, UsersContext } from "./Context";

import {
  Analysis,
  EligibilityAssessment,
  Extraction,
  IncomingPapers,
  Miscellaneous,
  NavBar,
  NotFoundPage,
  Release,
  SourceSelection,
} from "./MainViews";
import { ROUTES } from "./routes";

const Administration = React.lazy(() => import("./administration/Administration"));

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
  palette: {
    background: {
      default: "#ffffff",
    },
    primary: {
      main: "#253f5b",
    },
    secondary: {
      main: "#6f93ae",
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '10px 0 4px',
          color: 'black',
          "&:focus": {
            background: "white",
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          boxSizing: 'border-box'
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.54)',
          fontWeight: '400',
          lineHeight: '1',
          letterSpacing: '0.00938em'
        },
        shrink: {
          transform: 'translate(0, -9px) scale(0.75)',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingTop: '10.5px',
          paddingBottom: '10.5px'
        },
        inputMultiline: {
          paddingTop: '0',
          paddingBottom: '0'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: '#0000008A'
        }
      }
    }
  },
  overrides: {
    MuiButton: { 
        root: {
            background: '#6f93ae',
        },
    },
},
});

function Main() {
  let initLocation = localStorage.getItem("lastLocation")
    ? localStorage.getItem("lastLocation")
    : "/incoming-papers";
  const [users, setUsers] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [uiProperties, setUiProperties] = useState();


  useEffect(() => {
    api.getUsers().then((data) => {
      setUsers(data.sort());
    });

    api.getUser().then((data) => setCurrentUser(data));
  }, []);

  useEffect(() => {
    api
      .getUiProperties("current")
      .then((response) => {
        if (response.status === 200) {
          setUiProperties(response.data);
        }
      })
      .catch((e) => console.log("getUiProperties error", e));
  }, []);

  if (!users || !uiProperties) return null;

  return (
    <UsersContext.Provider value={{ users, currentUser }}>
      <UiPropertiesContext.Provider value={{ uiProperties, setUiProperties }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container maxWidth="xxl">
            <Routes>
              <Route
                path="/"
                element={<Navigate to={initLocation} replace />}
              />            
              <Route
                path={ROUTES.SOURCE_SELECTION}
                element={
                  <>
                    <NavBar />
                    <SourceSelection />
                  </>
                }
              />
              <Route
                path={ROUTES.INCOMING_PAPERS}
                element={
                  <>
                    <NavBar />
                    <IncomingPapers />
                  </>
                }
              />
              <Route
                path={ROUTES.ELIGIBILITY_ASSESSMENT}
                element={
                  <>
                    <NavBar />
                    <EligibilityAssessment />
                  </>
                }
              />
              <Route
                path={ROUTES.PAPER_ANALYSIS}
                element={
                  <>
                    <NavBar />
                    <Analysis />
                  </>
                }
              />
              <Route
                path={ROUTES.EXTRACTION}
                element={
                  <>
                    <NavBar />
                    <Extraction />
                  </>
                }
              />
              <Route
                path={ROUTES.RELEASE}
                element={
                  <>
                    <NavBar />
                    <Release />
                  </>
                }
              />
              <Route
                path={ROUTES.MISCELLANEOUS}
                element={
                  <>
                    <NavBar />
                    <Miscellaneous />
                  </>
                }
              />
              <Route path={ROUTES.ADMINISTRATION} element={<Suspense fallback={<div>Loading...</div>}> <Administration /> </Suspense>} />
              <Route
                path="*"
                element={
                  <>
                    <NavBar />
                    <NotFoundPage />
                  </>
                }
              />
            </Routes>
          </Container>
        </ThemeProvider>
      </UiPropertiesContext.Provider>
    </UsersContext.Provider>
  );
}

export default Main;
