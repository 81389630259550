import React, { useState } from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Chip,
} from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { useSnackbar } from 'notistack';
import { successSnackbarOptions } from '../notification/notifications';
import { getIdAndIdx } from '../../views/fetchData';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 120,
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  border: 0,
  outline: 0,
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      border: 0
    },
    '&::before': {
      left: 0,
      right: 0,
      bottom: 0,
      content: '""',
      position: 'absolute',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.87)'
    }
  },
}));

function AreaTopicSelect({
  areas,
  topics,
  initialAreaId,
  initialTopicId,
  handleChangeArea,
  handleChangeTopic,
  responsible,
}) {
  const [selectedAreaId, setSelectedAreaId] = useState(initialAreaId);
  const [selectedTopicId, setSelectedTopicId] = useState(initialTopicId);

  const { enqueueSnackbar } = useSnackbar();

  const onChangeArea = (e) => {
    setSelectedAreaId(e.target.value);
    handleChangeArea(e.target.value);
  };

  const onChangeTopic = (e) => {
    setSelectedTopicId(e.target.value);
    handleChangeTopic(e.target.value);
  };

  const SelectArea = createAreaSelector(areas, selectedAreaId, onChangeArea);
  const SelectTopic = createTopicSelector(topics, selectedTopicId, onChangeTopic);

  const responsibleChip = (
    <Box ml={3} mt={3}>
      <Chip size="small" color="primary" label={responsible} />
    </Box>
  );

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="row" justifyContent="flex-start">
        {SelectArea}
        {copyToClipBoard(areas, selectedAreaId, 'name', enqueueSnackbar)}
        {responsible && responsibleChip}
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-start">
        {SelectTopic}
        {copyToClipBoard(topics, selectedTopicId, 'title', enqueueSnackbar)}
      </Box>
    </React.Fragment>
  );
}

const copyToClipBoard = (array, fieldId, field, enqueueSnackbar) => {
  let idx = array.findIndex(({ id }) => id === fieldId);
  return (
    <IconButton
      onClick={() => {
        navigator.clipboard.writeText(array[idx][field]).then(
          () => {
            enqueueSnackbar('Copied!', successSnackbarOptions);
          },
          () => {
            console.log('Error with clipboard!');
          }
        );
      }}
      edge="start"
      disableRipple
      disableFocusRipple
      size="small"
    >
      <FileCopyOutlinedIcon viewBox="-5 -5 40 15"></FileCopyOutlinedIcon>
    </IconButton>
  );
};

function createAreaSelector(areas, selectedAreaId, handleChangeArea) {
  let areaItems = [];

  if (areas.length > 0) {
    areaItems = areas.map((item) => {
      return (
        <MenuItem dense key={item.id.toString()} value={item.id}>
          {item.name}
        </MenuItem>
      );
    });
  } else {
    areaItems.push(
      <MenuItem key={0} value={0}>
        No area items
      </MenuItem>
    );
  }

  const SelectArea = (
    <StyledFormControl margin="dense">
      <InputLabel id="in_select_area">Area</InputLabel>
      <StyledSelect
        labelId="select_area"
        id="select_area"
        value={selectedAreaId}
        onChange={handleChangeArea}
        style={{ paddingLeft: 10 }}
      >
        {areaItems}
      </StyledSelect>
    </StyledFormControl>
  );

  return SelectArea;
}

function createTopicSelector(
  topics,
  selectedTopicId,
  handleChangeTopic,
  classes
) {
  let topicItems = [];

  let idAndIdx = getIdAndIdx(topics, selectedTopicId);

  if (topics.length > 0) {
    topicItems = topics.map((item) => {
      return (
        <MenuItem dense key={item.id.toString()} value={item.id}>
          {item.title}
        </MenuItem>
      );
    });
  } else {
    topicItems.push(
      <MenuItem key={0} value={0}>
        No topic items
      </MenuItem>
    );
  }

  const SelectTopic = (
    <StyledFormControl margin="dense">
      <InputLabel id="in_select_topic">Topic</InputLabel>
      <StyledSelect
        // variant="outlined"
        labelId="select_topic"
        id="select_topic"
        value={idAndIdx.id}
        onChange={handleChangeTopic}
        style={{ paddingLeft: 10 }}
      >
        {topicItems}
      </StyledSelect>
    </StyledFormControl>
  );

  return SelectTopic;
}

export default AreaTopicSelect;
